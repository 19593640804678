<template>
    <div class="dealers-wrapper"
        :class="{ 'is-loading': isLoading, 'is-compact': compactMode == 'true', 'is-initial': isInitialSearch, 'no-consent': !hasConsent }"
        :style="{ '--dealers-form-height': this.formHeight + 'px', '--dealers-form-height-offset': this.formHeightOffset + 'px' }"
    >
        <div class="container">
            <gmap-map
                v-if="hasConsent"
                class="dealers-map google-map"
                ref="map"
                :center="mapCenter"
                :zoom="mapZoom"
                :options="mapOptions"
            >
                <gmap-marker
                    v-for="(m, index) in markers"
                    :key="index"
                    :position="m.coords"
                    :clickable="true"
                    :icon="m.icon"
                    @click="showMarkerDetails(m)"
                />

                <gmap-info-window
                    v-if="dealer"
                    :options="infoWindowOptions"
                    :position="infoWindowPos"
                    :opened="infoWindowOpen"
                    @closeclick="infoWindowOpen = false"
                >
                    <dealer-item :dealer="dealer" />
                </gmap-info-window>
            </gmap-map>

            <div class="dealers-card card" ref="defaultForm">
                <div class="card-body">
                    <h3>{{ $t('dealers.map_title') }}</h3>
                    <p>{{ $t('dealers.map_description') }}</p>

                    <div class="dealers-form form-style custom">
                        <div class="field" :class="{ 'current-location-active': isCurrentLocation }">
                            <label for="inputDealerSearchDefault" class="form-label">{{ $t('dealers.postal_code_or_city') }}</label>
                            <gmap-autocomplete
                                :componentRestrictions="countryRestrictions"
                                :setFieldsTo="['geometry']"
                                @place_changed="getLocationCoords"
                            >
                                <input
                                    class="form-control"
                                    id="inputDealerSearchDefault"
                                    type="search"
                                    ref="input"
                                    :placeholder="isCurrentLocation ? $t('dealers.postal_code_or_city_placeholder_current') : $t('dealers.postal_code_or_city_placeholder_default')"
                                    v-bind="$attrs"
                                    v-on="$listeners"
                                />
                            </gmap-autocomplete>

                            <a v-if="geolocationSupported" href="#" class="use-location" @click.prevent="getCurrentLocationCoords">
                                <ph-crosshair />
                                <span>{{ $t('dealers.use_current_location') }}</span>
                            </a>
                        </div>

                        <div v-if="hasConsent" class="field">
                            <label for="inputRadius" class="form-label">{{ $t('dealers.radius') }}</label>
                            <select id="inputRadius" class="form-select" v-model="locationDistance">
                                <option v-for="(dist, index) in locationDistanceOptions" :key="index" :value="dist">{{ dist }}km</option>
                            </select>
                        </div>

                        <button class="btn btn-primary w-100 mt-1" @click.prevent="doSearch()" :disabled="isLoading">
                            <div v-if="isLoading" class="loader">
                                <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                            </div>
                            <span>{{ $t('dealers.search') }}</span>
                        </button>
                    </div>
                </div>

                <svg width="244" height="26" viewBox="0 0 244 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="fake-clip-bottom">
                    <path d="M240.923 9.22094C147.607 30.7796 47.163 25.9341 3.43922 20.4307C1.45402 20.1809 -7.68741e-05 18.4889 -7.70491e-05 16.488L1.30613e-05 -7.02357e-05L244 -9.15527e-05L244 5.33954C244 7.19223 242.728 8.8039 240.923 9.22094Z" class="fill"/>
                </svg>
            </div>

            <div class="dealers-overview" v-if="!isInitialSearch">
                <div class="dealers-overview-body">
                    <div class="dealers-results">
                        <h3>{{ $t('dealers.map_title_results') }}</h3>
                        <small>{{ dealers.length }} {{ $t('dealers.map_label_results') }}</small>
                    </div>

                    <div class="dealers-form form-style custom">
                        <div class="field" :class="{ 'current-location-active': isCurrentLocation }">
                            <label for="inputDealerSearchInline" class="form-label">{{ $t('dealers.postal_code_or_city') }}</label>
                            <gmap-autocomplete
                                :componentRestrictions="countryRestrictions"
                                :setFieldsTo="['geometry']"
                                @place_changed="getLocationCoords"
                            >
                                <input
                                    class="form-control"
                                    id="inputDealerSearchInline"
                                    type="search"
                                    ref="input"
                                    :placeholder="isCurrentLocation ? $t('dealers.postal_code_or_city_placeholder_current') : $t('dealers.postal_code_or_city_placeholder_default')"
                                    v-bind="$attrs"
                                    v-on="$listeners"
                                />
                            </gmap-autocomplete>

                            <a v-if="geolocationSupported" href="#" class="use-location" @click.prevent="getCurrentLocationCoords">
                                <ph-crosshair />
                                <span>{{ $t('dealers.use_current_location') }}</span>
                            </a>
                        </div>

                        <div v-if="hasConsent" class="field">
                            <label for="inputRadius" class="form-label">{{ $t('dealers.radius') }}</label>
                            <select id="inputRadius" class="form-select" v-model="locationDistance">
                                <option v-for="(dist, index) in locationDistanceOptions" :key="index" :value="dist">{{ dist }}km</option>
                            </select>
                        </div>

                        <button class="btn btn-primary" @click.prevent="doSearch()" :disabled="isLoading">
                            <div v-if="isLoading" class="loader">
                                <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                            </div>
                            <ph-magnifying-glass />
                            <span>{{ $t('dealers.search') }}</span>
                        </button>
                    </div>

                    <ul class="dealers-list" v-if="dealers.length > 0">
                        <dealer-item
                            v-for="d in dealers"
                            :key="d.id"
                            :dealer="d"
                            @click="showDealerDetails(d)"
                            is-list
                        />
                    </ul>

                    <p class="dealers-empty" v-if="dealers.length == 0">
                        {{ $t('dealers.map_empty_results') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PhCrosshair, PhMagnifyingGlass } from 'phosphor-vue';
import { googleMapsMinimalTheming } from '../../../config/google-maps';

import shopware from '../../mixins/shopware';
import dealer from '../../mixins/dealer';

export default {
    components: {
        PhCrosshair,
        PhMagnifyingGlass
    },
    mixins: [shopware, dealer],
    props: {
        compactMode: String,
    },
    data() {
        return {
            isInitialSearch: true,
            mapOptions: {
                disableDefaultUI: true,
                minZoom: 8,
                maxZoom: 13,
                styles: googleMapsMinimalTheming
            },
            formWidth: null,
            formHeight: null,
            formHeightOffset: 95, // additional fake-clip-bottom SVG height
        };
    },
    computed: {
        isNarrowViewport: function() {
            return window.innerWidth < 992; // "md" breakpoint
        }
    },
    async created() {
        await this.initContext();
        this.initMap();

        this.doSearch();
    },
    mounted() {
        this.locationDistance = this.locationDistanceOptions[0];

        this.refreshFormDimensions();
    },
    methods: {
        refreshFormDimensions() {
            this.$nextTick(() => {
                this.formWidth = this.$refs.defaultForm.clientWidth;
                this.formHeight = this.$refs.defaultForm.clientHeight + this.formHeightOffset;
            });
        },

        triggerSearchEvent(input){
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'DealerGezocht',
                place: input,
                radius: this.locationDistance
            });
        },

        fitBoundsMarkersHandler(bounds) {
            this.refreshFormDimensions();
            this.$refs.map.fitBounds(bounds);

            if (this.isNarrowViewport) {
                this.$refs.map.panBy(0, -(this.formHeight / 4));

                const element = this.$refs.defaultForm;

                if (element) {
                    const rect = element.getBoundingClientRect();
                    const scrollAmount = rect.top + window.scrollY + (rect.height - 100) // Calculate the amount to scroll
                    window.scrollTo({
                        top: scrollAmount,
                        behavior: 'smooth' // Smooth scroll transition
                    });
                }
            } else {
                this.$refs.map.panBy(-(this.formWidth), 0);
            }
        },

        getSearchInput() {
            const input = document.getElementById((this.isInitialSearch == true || this.compactMode == 'false') ? 'inputDealerSearchDefault' : 'inputDealerSearchInline');
            return input?.value;
        },

        async doSearch() {
            let fitBounds = true;
            let input = this.getSearchInput();
            let endpoint = '/dealers/list';
            let params = {
                segment: this.segment,
                lat: this.locationCoords.lat,
                lng: this.locationCoords.lng,
                distance: this.locationDistance,
                country: JSON.parse(this.countries).join(','),
                limit: 10000
            };

            if (this.dealers.length > 0) {
                this.isInitialSearch = false;
                this.triggerSearchEvent(input);
            }

            if (this.isInitialSearch) {
                fitBounds = false;
                params.distance = 75;
            }

            if (!this.hasConsent) {
                if (!input) {
                    return;
                }

                delete params.lat;
                delete params.lng;
                delete params.distance;

                params.search = input;
                params.limit = 15;
            }

            try {
                this.isLoading = true;

                const result = await this.api.client.invoke(`dealers post ${endpoint}`, params);
                this.dealers = result.data;
            } catch (error) {
                if (error.status !== 404) {
                    console.error(error);
                }

                this.dealers = [];
                fitBounds = false;
            }

            if (this.dealers.length > 0 && !this.hasConsent) {
                this.isInitialSearch = false;
            }

            this.dealer = null;
            this.updateMarkers(fitBounds);

            this.isLoading = false;
        }
    }
};
</script>
