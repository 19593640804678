import { Modal } from 'bootstrap'; // Import only the Modal component

document.addEventListener('DOMContentLoaded', function () {
    var confirm_cookie_name = "dcm_confirm_region_modal";

    var modalElement = document.getElementById('languagesVerifyModal');
    if(!modalElement) {
        return;
    }

    var cookies = document.cookie.split(';').map(cookie => cookie.trim());
    var languageCookie = cookies.find(cookie => cookie.startsWith(confirm_cookie_name  + '='));

    const preferredLanguages = navigator.languages;
    const primaryLanguage = (preferredLanguages?.length ? preferredLanguages[0] : '');
    const primaryIsEnglish = primaryLanguage.toLowerCase().includes('en');

    // console.log("preferredLanguages:");
    // console.log(preferredLanguages);
    // console.log("primaryLanguage:");
    // console.log(primaryLanguage);
    // console.log("primaryIsEnglish:");
    // console.log(primaryIsEnglish);

    // console.log("cookies:");
    // console.log(cookies);
    // console.log("languageCookie:");
    // console.log(languageCookie);

    if (languageCookie || primaryIsEnglish) {
        return;
    } else {
        var modalInstance = new Modal(modalElement);
        modalInstance.show();

        document.cookie = confirm_cookie_name + "=modalShown; path=/; max-age=" + (60 * 60 * 24 * 365);
    }
});
